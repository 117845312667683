.App {
  text-align: center;
  background-color: #282c34;
}
.theme {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Appbody {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
img {
  align-self: center;
}
.skills {
  align-self: center;
  min-width: 100%;
}
.profile {
  align-self: center;
  max-width: 75%;
}
.projectImg {
  align-self: center;
  width: 250px;
}
@media only screen and (max-width: 600px) {
  .projectImg {
    align-self: center;
    width: 130px;
  }
}
a:link,
a:visited {
  color: white;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.projectContainer {
  background-color: lightgray;
  width: 80%;
  align-self: center;
  min-height: 400px;
  border-radius: 30px;
  display: flex;
}
.thumbnail {
  padding: 2;
  margin: 0;
}
.font {
  color: white;
}
.sections {
  padding-top: 100px;
  padding-bottom: 20px;
}
.links {
  cursor: pointer;
}
.selected {
  color: #d5a9e9;
}
a {
  font-size: 30px;
}
p {
  font-size: 15px;
}
.paragraph {
  max-width: 500px;

  align-self: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.smallBoxContainer {
  display: flex;
  align-items: center;
  width: 80%;
  align-self: center;
}
.smallBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.verticalLine {
  height: 160px;
  width: 1px;
  background-color: white;
  margin-left: 45px;
  margin-right: 45px;
}
.horizontalLine {
  width: 80%;
  height: 1px;
  border: 1px;
  background-color: #282c34;
  margin-bottom: 30px;
}
.spaceAbove {
  padding-top: 60px;
}
.sideByside {
  display: flex;
  flex-direction: column;
  width: 35%;
  align-items: flex-end;
}
.sideBysideR {
  display: flex;
  flex-direction: column;
  width: 35%;
  align-items: flex-start;
}
.bold {
  font-weight: bold;
}
.alignL {
  text-align: left;
}
.buttonlink {
  background-color: black;
  color: red;
}
.rel2 {
  padding: 20px;
  top: 20%;
  width: 20%;
  font-weight: 600;
  color: lightgreen;
  font-size: 40px;
}
#abs2 {
  background-image: url("background.jpg");
}
.white {
  color: white;
  height: 500;
}
.pop {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
}
.link:hover {
  color: #d5a9e9;
}
.link {
  font-size: 25px;
}
/* Style inputs with type="text", select elements and textareas */
input[type="text"],
select,
textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}
input[type="email"],
select,
textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type="submit"]:hover {
  background-color: #45a049;
}

/* Add a background color and some padding around the form */
.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
.submitbtn {
  padding: 4px;
  border-radius: 4px;
}
.formg {
  width: 400px;
}
.verdana {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
